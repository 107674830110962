exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bowls-js": () => import("./../../../src/pages/bowls.js" /* webpackChunkName: "component---src-pages-bowls-js" */),
  "component---src-pages-seasons-js": () => import("./../../../src/pages/seasons.js" /* webpackChunkName: "component---src-pages-seasons-js" */),
  "component---src-templates-bowl-js": () => import("./../../../src/templates/bowl.js" /* webpackChunkName: "component---src-templates-bowl-js" */),
  "component---src-templates-retiredbowl-js": () => import("./../../../src/templates/retiredbowl.js" /* webpackChunkName: "component---src-templates-retiredbowl-js" */),
  "component---src-templates-season-js": () => import("./../../../src/templates/season.js" /* webpackChunkName: "component---src-templates-season-js" */)
}

